import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";
import { toast } from "react-toastify";

export const getPackages = createAsyncThunk(
  "getPackagesFunc",
  async ({ page, size, searchValue }, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await axios.get(
        `/restaurant-packages?${page ? `page=${page}` : `page=${1}`}&${
          size ? `size=${size}` : `size=${12}`
        }&${searchValue && searchValue !== "" ? `search=${searchValue}` : ""}`
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSubPackages = createAsyncThunk(
  "getSubPackagesFunc",
  async ({ page, size }, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await axios.get(
        `/restaurant-packages/subscribed?${
          page ? `page=${page}` : `page=${1}`
        }&${size ? `size=${size}` : `size=${12}`}`
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const subscribePackage = createAsyncThunk(
  "subscribePackageFunc",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await toast.promise(
        axios.post(`/restaurant-packages/${id}/subscribe`, data),
        {
          pending: "Loading...",
          success: "Subscribed to packaged successfully",
        },
        { toastId: "subPackagesID" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const unSubscribePackage = createAsyncThunk(
  "unSubscribePackageFunc",
  async (data, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await toast.promise(
        axios.post(`/restaurant-packages/unsubscribe`, data),
        {
          pending: "Loading...",
          success: "Unsubscribed to packaged successfully",
        },
        { toastId: "unsubPackagesID" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editSubscribePackage = createAsyncThunk(
  "editSubscribePackageFunc",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await toast.promise(
        axios.put(`/restaurant-packages/subscribed/${id}`, data),
        {
          pending: "Loading...",
          success: "Packaged Updated successfully",
        },
        { toastId: "EditsubPackagesID" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// admin endpoints
export const getAdminPackages = createAsyncThunk(
  "getAdminPackagesFunc",
  async (
    { page, size, col, dir, searchValue },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;
    if (col) {
      colCheck =
        col === "ID"
          ? "id"
          : col === "قيمة الخصم" || col === "Discount Value"
          ? "discount_value"
          : col === "Package Name (EN)" || col === "اسم الباقة (EN)"
          ? "package_name"
          : col === "Package Name (AR)" || col === "اسم الباقة (AR)"
          ? "package_name"
          : col === "تاريخ الانشاء" || col === "Created At"
          ? "created_at"
          : col.replace(/\s+/g, "").toLowerCase();
    }
    // console.log(page,size);
    const url = col
      ? `/subscription-packages?${page ? `page=${page}` : `page=${1}`}&${
          size ? `size=${size}` : `size=${12}`
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${
          searchValue ? `&search=${searchValue}` : ""
        }`
      : `/subscription-packages?${page ? `page=${page}` : `page=${1}`}&${
          size ? `size=${size}` : `size=${12}`
        }&${searchValue ? `search=${searchValue}` : ""}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addPackage = createAsyncThunk(
  "addPackageFunc",
  async (data, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await toast.promise(
        axios.post(`/subscription-packages`, data),
        {
          pending: "Loading...",
          success: "Packaged Added successfully",
        },
        { toastId: "AddPackagesID" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editPackage = createAsyncThunk(
  "editPackageFunc",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await toast.promise(
        axios.put(`/subscription-packages/${id}`, data),
        {
          pending: "Loading...",
          success: "Packaged Added successfully",
        },
        { toastId: "editPackagesID" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// admin archived packages

export const getArchivedPackages = createAsyncThunk(
  "getArchivedPackagesFunc",
  async (
    { page, size, searchValue, col, dir },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;
    if (col) {
      colCheck =
        col === "ID"
          ? "id"
          : col === "قيمة الخصم" || col === "Discount Value"
          ? "discount_value"
          : col === "Package Name (EN)" || col === "اسم الباقة (EN)"
          ? "package_name"
          : col === "Package Name (AR)" || col === "اسم الباقة (AR)"
          ? "package_name"
          : col === "تاريخ الارشفة" || col === "Archived At"
          ? "archived_at"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/subscription-packages/archived?${
          page ? `page=${page}` : `page=${1}`
        }&${size ? `size=${size}` : `size=${12}`}&${
          searchValue ? `search=${searchValue}` : ""
        }`
      : `/subscription-packages/archived?${
          page ? `page=${page}` : `page=${1}`
        }&${
          size ? `size=${size}` : `size=${12}`
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${
          searchValue ? `&search=${searchValue}` : ""
        }`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const toggleArchivePackage = createAsyncThunk(
  "toggleArchivePackageFunc",
  async ({ status, id }, { rejectWithValue, dispatch }) => {
    // console.log(page,size);
    try {
      const res = await toast.promise(
        axios.get(`/subscription-packages/${id}/${status}`),
        {
          pending: "Loading...",
          success: `Packaged ${status}d successfully`,
        }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  packages: [],
  subPackages: [],
  adminPackages: [],
  archivePackages: [],
  error: null,
};
export const Packages = createSlice({
  name: "Packages-Func",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(getPackages.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.packages = action.payload;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(getPackages.rejected, (state, action) => {
        state.loading = false;
        state.packages = [];
        state.error = action.payload;
      })

      .addCase(getSubPackages.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getSubPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.subPackages = action.payload;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(getSubPackages.rejected, (state, action) => {
        state.loading = false;
        state.subPackages = [];
        state.error = action.payload;
      })

      .addCase(subscribePackage.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(subscribePackage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(subscribePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(unSubscribePackage.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(unSubscribePackage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(unSubscribePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(editSubscribePackage.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(editSubscribePackage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(editSubscribePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // admin functions

      .addCase(getAdminPackages.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getAdminPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.adminPackages = action?.payload;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(getAdminPackages.rejected, (state, action) => {
        state.loading = false;
        state.adminPackages = [];
        state.error = action.payload;
      })

      .addCase(addPackage.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(addPackage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(addPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getArchivedPackages.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getArchivedPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.archivePackages = action?.payload;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(getArchivedPackages.rejected, (state, action) => {
        state.loading = false;
        state.archivePackages = [];
        state.error = action.payload;
      })

      .addCase(toggleArchivePackage.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(toggleArchivePackage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(toggleArchivePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default Packages.reducer;
